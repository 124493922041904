/* screen - tokenomics */

.tokenomics {
    align-items: flex-start;
    background-color: var(--white);
    display: flex;
    height: 1080px;
    overflow: hidden;
    width: 1920px;
  }
  
  .tokenomics .the-last-fax-1 {
    height: 1080px;
    left: 2px;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 1920px;
  }
  
  .tokenomics .tokenomics-1 {
    display: block;
    height: 1080px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    transform: translate(0, 200px);
    width: 1920px;
  }
  
  .tokenomics .tokenomics-1.animate-enter3 {
    animation: animate-enter3-frames 2.00s ease-in-out 1.00s 1 normal forwards;
    display: block;
    transform: translate(0, 200px);
  }
  
  @keyframes animate-enter3-frames {
    from{opacity: 1;
  transform: translate(0, 200px);
  }
  to{opacity: 1;
  transform: translate(0,0);
  }
  }
  
  .tokenomics .graph-button:hover {
    box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.2);
  }
  
  .tokenomics .face-button:hover {
    box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.2);
  }
  
  .tokenomics .x-button:hover {
    box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.2);
  }
  
  .tokenomics .telegram-button:hover {
    box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.2);
  }
  
  .tokenomics .the-last-fax-button:hover {
    box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.2);
  }
  
  .tokenomics .buy-button:hover {
    box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.2);
  }
  
  .tokenomics .live-figures-button:hover {
    box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.2);
  }
  
  .tokenomics .tokenomics-button:hover {
    box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.2);
  }
  
  .tokenomics .redlight {
    background-color: #ff0004;
    border-radius: 17.88px;
    filter: blur(30px);
    height: 36px;
    left: 1045px;
    position: absolute;
    top: 736px;
    width: 36px;
  }
  
  @keyframes blink {
      0%, 100% {
          opacity: 1;
      }
      50% {
          opacity: 0;
      }
  }
  
  .redlight {
      animation: blink 1s infinite;
  }
  
  