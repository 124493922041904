/* screen - live-figures */

.live-figures {
    align-items: flex-start;
    background-color: var(--white);
    display: flex;
    height: 1080px;
    overflow: hidden;
    width: 1920px;
  }
  
  .live-figures .tokenomics-1 {
    height: 1080px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 1920px;
  }
  
  .live-figures .the-last-fax-1 {
    height: 1080px;
    left: 2px;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 1920px;
  }
  
  .live-figures .blank-page-2.animate-enter {
    animation: animate-enter-frames 2.00s ease-in-out 1.00s 1 normal forwards;
    display: block;
    transform: translate(0, 200px);
  }
  
  @keyframes animate-enter-frames {
    from{opacity: 1;
  transform: translate(0, 200px);
  }
  to{opacity: 1;
  transform: translate(0,0);
  }
  }
  
  .live-figures .graph-button:hover {
    box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.2);
  }
  
  .live-figures .face-button:hover {
    box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.2);
  }
  
  .live-figures .x-button:hover {
    box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.2);
  }
  
  .live-figures .telegram-button:hover {
    box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.2);
  }
  
  .live-figures .the-last-fax-button:hover {
    box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.2);
  }
  
  .live-figures .buy-button:hover {
    box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.2);
  }
  
  .live-figures .live-figures-button:hover {
    box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.2);
  }
  
  .live-figures .tokenomics-button:hover {
    box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.2);
  }
  
  .live-figures .redlight {
    background-color: #ff0004;
    border-radius: 17.88px;
    filter: blur(30px);
    height: 36px;
    left: 1045px;
    position: absolute;
    top: 736px;
    width: 36px;
  }
  
  @keyframes blink {
      0%, 100% {
          opacity: 1;
      }
      50% {
          opacity: 0;
      }
  }
  
  .redlight {
      animation: blink 1s infinite;
  }
  
  .overlap-group img {
    /*z-index: 1;  Background images should have a lower z-index */
  }
  .coloring {
    background-color: #ff0004;
  }

  .greener{
    background-color: #00ff00;
  }

  .page-content{
    position: relative;
    margin: 0 auto;
    top: 50%;
    left: 50%;
    background-color: white;
    width: 200px;
    height: 200px;
    
  }

  .page-content2 {
    width: 270px; /* Adjust the width as needed */
    height: 207px; /* Adjust the height as needed */
    background-image: url('../../public/img/small.png'); /* The white color of your div */
    /* Replace -10deg with the actual angle of the paper */
    /* Optional: to place it similarly to the paper in the image */
    position: absolute;
    top: 23.0%; /* Adjust positioning as needed */
    left: 47.3335%; /* Adjust positioning as needed */
    transform-origin: center;
  }

  .image-text{
    width: 100%;
    height: 100%;
    transform: rotate(4.0deg);
    margin: 20px;
  }

  .text-2{
    font-size: 12px;
    text-align: center;
    margin-top: 10px;
    margin-right: 50px;
    font-family: 'Arial', sans-serif;
    font-weight: bold;
  }
  
  .text-1{
    font-family: 'Arial', sans-serif;
    font-weight: bold;
    /* font-size: 20px; */
  }
